import { useQuery } from 'react-query';

import { axios } from 'src/lib/axios';
import { QueryConfig } from 'src/lib/react-query';

type ClinicQuery = {
  id: number;
  name: string;
  avatar: string;
  dental_practice: any;
  location: any;
  country: string;
  geopoint: any;
  is_admin: boolean;
  address: string;
  line_channel: any;
  created_at: string;
  updated_at: string;
};

export const getClinic = (clinic: string | null, country?: string): Promise<ClinicQuery> => {
  if (!clinic) {
    return axios.get(`/sch/clinics/default/${country}`);
  }
  return axios.get(`/sch/clinics/${clinic}`);
};

type useClinicOptions = {
  clinic: string | null;
  country?: string;
  config?: QueryConfig<typeof getClinic>;
};

export const useClinic = ({ clinic, country, config }: useClinicOptions) => {
  return useQuery<ClinicQuery>({
    ...config,
    enabled: clinic || country ? true : false,
    queryKey: ['onboardingClinic', clinic, country],
    queryFn: () => getClinic(clinic, country),
  });
};
