import { useEffect } from 'react';
import { Button, Image, Text } from '@chakra-ui/react';
const maxAge = 120;
import { customAlphabet } from 'nanoid';
import storage from 'src/utils/storage';
import lineIcon from 'src/assets/images/line.png';
import { useQuerySearchParams } from 'src/hooks/useSearchParams';
//import { consoleLog } from 'src/utils/consoleLog';

// documentation: https://developers.line.biz/en/docs/line-login/integrate-line-login/
// button guidelines: https://developers.line.biz/en/docs/line-login/login-button/#design-guidelines

export const LineLogin = ({
  clientID,
  clinicQueryId,
  state,
  scope,
  locale,
  setIdToken,
  redirectURI,
  isLoading,
  text,
  isDisabled = false,
  width = 230,
  loginType = 'login',
}) => {
  const queryParams = useQuerySearchParams();
  const lineLogin = () => {
    // random generator
    const nanoid = customAlphabet(
      'useandom26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict',
      15
    );
    const nonce = nanoid();
    storage.setLineNonce(nonce);
    storage.setLineLoginType(loginType);
    storage.setLineClinicRegister(clinicQueryId);
    // Build query string.
    const query = new URLSearchParams();
    query.set('response_type', 'code');
    query.set('client_id', clientID);
    query.set('state', state);
    query.set('scope', scope);
    query.set('nonce', nonce);
    query.set('ui_locales', locale);
    query.set('prompt', 'consent');
    query.set('max_age', maxAge);
    //query.set('bot_prompt', 'normal');
    //query.set('initial_amr_display', 'lineqr');
    query.set('bot_prompt', 'aggressive');

    // Build the Line authorise URL.
    const lineAuthoriseURL =
      'https://access.line.me/oauth2/v2.1/authorize?' +
      query.toString() +
      '&redirect_uri=' +
      redirectURI;
    // Redirect to external URL.
    window.location.href = lineAuthoriseURL;
  };

  const getAccessToken = () => {
    var hasCodeProperty = queryParams.has('code');

    if (hasCodeProperty) {
      if (setIdToken) {
        setIdToken({
          code: queryParams.get('code'),
          loginType: storage.getLineLoginType(),
          nonce: storage.getLineNonce(),
          clinicId: storage.getLineClinicRegister(),
        });
      }
    }
  };

  useEffect(() => {
    // check if page is from redirected line login
    // then get access token and trigger the login function
    if (queryParams.get('code')) {
      getAccessToken(window.location.href);
    }
  }, [clientID]);
  // press #06C7554D
  return (
    <Button
      backgroundColor={'#06C755'}
      leftIcon={<Image height="40px" src={lineIcon} />}
      _hover={{
        backgroundImage: 'linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1))',
        backgroundColor: '#06C755',
      }}
      _active={{
        backgroundImage: 'linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3))',
        backgroundColor: '#06C755',
      }}
      _disabled={{
        bg: '#EFEFEF',
        color: '#1E1E1E33',
        border: '1px solid #E5E5E599',
      }}
      color="white"
      isLoading={isLoading}
      width={`${width}px`}
      borderRadius="10px"
      height="50px"
      onClick={() => lineLogin()}
      isDisabled={isDisabled}
    >
      <Text ml={2}>{text}</Text>
    </Button>
  );
};
