const storagePrefix = 'my_beforedent_';

const storage = {
  set: (key: string, value: string) => {
    window.localStorage.setItem(`${storagePrefix}${key}`, JSON.stringify(value));
  },
  get: (key: string): string => {
    try {
      return JSON.parse(window.localStorage.getItem(`${storagePrefix}${key}`) as string);
    } catch (err) {
      return '';
    }
  },
  clear: (key: string) => {
    window.localStorage.removeItem(`${storagePrefix}${key}`);
  },
  setHomePage: (homePage: string) => {
    window.localStorage.setItem(`${storagePrefix}homePage`, JSON.stringify(homePage));
  },
  getHomePage: (): string => {
    try {
      return JSON.parse(window.localStorage.getItem(`${storagePrefix}homePage`) as string);
    } catch (err) {
      return '/';
    }
  },
  clearHomePage: () => {
    window.localStorage.removeItem(`${storagePrefix}homePage`);
  },
  setClinic: (clinicId: string) => {
    window.localStorage.setItem(`${storagePrefix}clinic`, JSON.stringify(clinicId));
  },
  getClinic: (): string => {
    try {
      return JSON.parse(window.localStorage.getItem(`${storagePrefix}clinic`) as string);
    } catch (err) {
      return '';
    }
  },
  clearClinic: () => {
    window.localStorage.removeItem(`${storagePrefix}clinic`);
  },
  getToken: () => {
    try {
      return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string);
    } catch (err) {
      return '';
    }
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  setCountry: (country: string) => {
    window.localStorage.setItem(`${storagePrefix}country`, JSON.stringify(country));
  },
  getCountry: (): string => {
    try {
      return JSON.parse(window.localStorage.getItem(`${storagePrefix}country`) as string);
    } catch (err) {
      return '';
    }
  },
  clearCountry: () => {
    window.localStorage.removeItem(`${storagePrefix}country`);
  },
  setLocale: (locale: string) => {
    window.localStorage.setItem(`${storagePrefix}locale`, JSON.stringify(locale));
  },
  getLocale: (): string => {
    try {
      return JSON.parse(window.localStorage.getItem(`${storagePrefix}locale`) as string);
    } catch (err) {
      return 'en';
    }
  },
  setLineNonce: (nonce: string) => {
    window.localStorage.setItem(`${storagePrefix}linenonce`, JSON.stringify(nonce));
  },
  getLineNonce: (): string => {
    try {
      return JSON.parse(window.localStorage.getItem(`${storagePrefix}linenonce`) as string);
    } catch (err) {
      return '';
    }
  },
  clearLineNonce: () => {
    window.localStorage.removeItem(`${storagePrefix}linenonce`);
  },
  setLineLoginType: (loginType: string) => {
    window.localStorage.setItem(`${storagePrefix}linelogintype`, JSON.stringify(loginType));
  },
  clearLineLoginType: () => {
    window.localStorage.removeItem(`${storagePrefix}linelogintype`);
  },
  getLineLoginType: (): string => {
    try {
      return JSON.parse(window.localStorage.getItem(`${storagePrefix}linelogintype`) as string);
    } catch (err) {
      return '';
    }
  },
  setLineClinicRegister: (clinicId: string) => {
    window.localStorage.setItem(`${storagePrefix}lineclinicregister`, JSON.stringify(clinicId));
  },
  clearLineClinicRegister: () => {
    window.localStorage.removeItem(`${storagePrefix}lineclinicregister`);
  },
  getLineClinicRegister: (): string => {
    try {
      return JSON.parse(
        window.localStorage.getItem(`${storagePrefix}lineclinicregister`) as string
      );
    } catch (err) {
      return '';
    }
  },
  getViewerHelpDialog: () => {
    try {
      return JSON.parse(window.localStorage.getItem(`${storagePrefix}viewerHelpDialog`) as string);
    } catch (err) {
      return '';
    }
  },
  setViewerHelpDialog: () => {
    window.localStorage.setItem(`${storagePrefix}viewerHelpDialog`, JSON.stringify('no'));
  },
};

export default storage;
