import { Icon } from '@chakra-ui/react';

const SelectDropdownIcon = (props: any) => {
  return (
    <Icon width="5px" height="3px" viewBox="0 0 5 3" color={'#1B2B29'} {...props}>
      <path
        d="M0.167708 4.22452e-07L4.83229 1.46616e-08C4.9722 2.43031e-09 5.05032 0.147178 4.96368 0.247656L2.63139 2.94215C2.56463 3.01928 2.43608 3.01928 2.36861 2.94215L0.0363212 0.247657C-0.050323 0.147179 0.0277987 4.34684e-07 0.167708 4.22452e-07Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default SelectDropdownIcon;
