import { axiosBare } from 'src/lib/axiosBare';

import { UserResponse } from '../types';

export type LoginCredentialsSocialLineOld = {
  code: string;
  provider: string;
  friendship_status_changed: boolean;
  id_token: string;
  user_id: string;
  country: string;
  nonce?: string;
};

export type LoginCredentialsSocialLine = {
  code: string;
  provider: string;
  country: string;
  nonce?: string;
  channel?: string;
  clinic?: number;
  phone_number?: string;
};

export type LoginCredentials = {
  provider: string;
  email: string;
  password: string;
};

/**
 * login with LINE when a specific clinic is given
 */
export const registerWithLineClinic = (data: LoginCredentialsSocialLine): Promise<UserResponse> => {
  return axiosBare.post(`/u/auth/register/${data?.clinic}`, data);
};

/**
 * login with LINE when there is no specific clinic given
 */
export const loginWithLine = (data: LoginCredentialsSocialLine): Promise<UserResponse> => {
  return axiosBare.post(`/u/auth/line/${data?.country}`, data);
};

/**
 * login with LINE when a specific clinic is given
 */
export const loginWithLineClinic = (data: LoginCredentialsSocialLine): Promise<UserResponse> => {
  //return axiosBare.post(`/u/auth/line/clinic/${data?.clinic}`, data);
  return axiosBare.post(`/u/auth/line/${data?.country}`, data);
};

/**
 * login with email and password
 */
export const login = (data: LoginCredentials): Promise<UserResponse> => {
  return axiosBare.post('/authen/login/', data);
};
