import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { Dict } from '@chakra-ui/utils';
import { StepsStyleConfig } from 'chakra-ui-steps';

const CustomSteps = {
  ...StepsStyleConfig,
};

const theme = extendTheme({
  textStyles: {
    p: {
      color: '#1B2B29',
    },
  },
  colors: {
    brandDark: {
      50: '#defffd',
      100: '#b3fff7',
      200: '#86fef2',
      300: '#5afeed',
      400: '#3dfee8',
      500: '#31e5ce',
      600: '#21b2a0',
      700: '#137f73',
      800: '#004d46',
      900: '#001b17',
      primary: '#005750',
    },
    brand: {
      50: '#e3f3f1',
      100: '#b9e1dc',
      200: '#8bcdc5',
      300: '#5db9ae',
      400: '#3aaa9c',
      500: '#179b8b',
      600: '#149383',
      700: '#118978',
      800: '#0d7f6e',
      900: '#076d5b',
      primary: '#179B8B',
    },
    dark: '#1B2B29',
    darkGray: '#9F9F9F',
    teal: '#285E61',
    subtitle: '#7B8887',
    checkUps: '#56A9E8',
    whitening: '#FF645A',
    straightening: '#FBBE62',
    restorative: '#9391FD',
    misc: '#009482',
    inputBorder: 'rgba(123, 136, 135, 0.5)',
  },
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
  },
  components: {
    Steps: CustomSteps,
    Heading: {
      color: '#1B2B29',
      fontFamily: 'Poppins',
    },
    Text: {
      color: '#1B2B29',
      fontSize: '0.8em',
      fontFamily: 'Poppins',
    },
    Modal: {
      borderRadius: '50px',
      p: '40px',
    },
    FormLabel: {
      baseStyle: {
        fontSize: '0.8em',
      },
    },
    Select: {
      baseStyle: {
        field: {
          background: 'transparent',
          sx: {
            borderRadius: '10px',
            borderColor: 'rgba(123, 136, 135, 0.50)',
          },
          border: '1px solid rgba(123, 136, 135, 0.50)',
          color: '#000000',
          _placeholder: {
            color: '#7B8887',
          },
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          background: 'transparent',
          sx: {
            borderRadius: '10px',
          },
          color: '#000000',
          _placeholder: {
            color: '#7B8887',
          },
        },
      },
      sizes: {
        sm: {
          field: {
            borderRadius: '10px',
          },
        },
        md: {
          field: {
            fontSize: '0.8em',
            borderRadius: '10px',
          },
          fontSize: '0.8em',
          borderRadius: '10px',
        },
        lg: {
          field: {
            pl: '21px',
            fontSize: '14px',
            borderRadius: '15px',
          },
        },
      },
      variants: {
        outline: {
          field: {
            border: '1px solid rgba(123, 136, 135, 0.50)',
            borderColor: 'rgba(123, 136, 135, 0.50)',
          },
        },
        filled: {
          field: {
            border: '1px solid rgba(123, 136, 135, 0.5)',
            background: 'transparent',
            fontSize: '11px',
            rounded: '10px',
            color: '#000000',
            _placeholder: {
              color: '#7B8887',
              fontSize: '11px',
              lineHeight: '16px',
              paddingTop: '10px',
              paddingBottom: '10px',
            },
          },
        },
      },
    },
    Textarea: {
      baseStyle: {
        background: 'transparent',
        borderRadius: '10px',
        sx: {
          borderRadius: '10px',
        },
        color: '#000000',
        _placeholder: {
          color: '#7B8887',
        },
      },
      sizes: {
        sm: {
          borderRadius: '10px',
        },
        md: {
          fontSize: '0.8em',
          borderRadius: '10px',
        },
        lg: {
          pl: '21px',
          fontSize: '14px',
          borderRadius: '15px',
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: '15px',
      },
      sizes: {
        lg: {
          borderRadius: '15px',
          fontSize: '1em',
          fontWeight: 500,
          px: 5,
          py: 4,
        },
      },
      variants: {
        primary: (props: Dict<any>) => ({
          color: mode('white', 'gray.800')(props),
          fontWeight: 400,
          backgroundColor: mode('brand.primary', 'brand.200')(props),

          _hover: {
            backgroundColor: mode('brand.600', 'brand.300')(props),
            _disabled: {
              backgroundColor: mode('brand.700', 'brand.400')(props),
            },
          },
          _active: {
            backgroundColor: mode('brand.700', 'brand.400')(props),
          },
          _disabled: {
            backgroundColor: mode('brand.700', 'brand.400')(props),
          },
        }),
        outline: (props: Dict<any>) => ({
          color: mode('brand.primary', 'brand.700')(props),
          fontWeight: 400,
          backgroundColor: 'transparent',
          borderColor: 'brand.primary',
          _hover: {
            _disabled: {
              backgroundColor: '#EFEFEF',
              borderColor: '#C4C4C4',
            },
          },

          _disabled: {
            backgroundColor: '#EFEFEF',
            borderColor: '#C4C4C4',
          },
        }),
      },
    },
    Progress: {
      variants: {
        register: {
          filledTrack: {
            bg: '#005750',
          },
        },
      },
    },
  },
});

export default theme;
