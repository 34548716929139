import { Icon } from '@chakra-ui/react';

const NotificationIcon = (props: any) => {
  return (
    <Icon width="29px" height="29px" viewBox="0 0 29 29" color={'#005750'} count={0} {...props}>
      <g display={props?.count ? 'block' : 'none'}>
        <path
          d="M14.5026 27.614C15.0101 27.6023 15.4971 27.4115 15.8774 27.0754C16.2577 26.7393 16.507 26.2795 16.581 25.7773H12.3438C12.4199 26.2932 12.6807 26.7638 13.0779 27.1016C13.475 27.4395 13.9813 27.6215 14.5026 27.614V27.614Z"
          fill="currentColor"
        />
        <path
          d="M26.4587 22.6601L26.1848 22.4184C25.4078 21.7261 24.7277 20.9322 24.1628 20.0582C23.546 18.8519 23.1762 17.5345 23.0753 16.1834V12.204C23.0721 11.7206 23.029 11.2383 22.9465 10.762C21.5819 10.4816 20.3563 9.7382 19.4769 8.65776C18.5976 7.57732 18.1187 6.2262 18.1212 4.83316V4.32566C17.2801 3.91176 16.3765 3.63958 15.4467 3.5201V2.5051C15.4467 2.21989 15.3334 1.94635 15.1318 1.74467C14.9301 1.54299 14.6565 1.42969 14.3713 1.42969C14.0861 1.42969 13.8126 1.54299 13.6109 1.74467C13.4092 1.94635 13.2959 2.21989 13.2959 2.5051V3.56038C11.2141 3.85405 9.30886 4.89095 7.93189 6.47966C6.55491 8.06837 5.79918 10.1016 5.80424 12.204V16.1834C5.70334 17.5345 5.33361 18.8519 4.71674 20.0582C4.16161 20.9301 3.49245 21.7238 2.72701 22.4184L2.45312 22.6601V24.9318H26.4587V22.6601Z"
          fill="currentColor"
        />
        <path
          d="M24.1684 8.86122C26.3929 8.86122 28.1962 7.05792 28.1962 4.83344C28.1962 2.60896 26.3929 0.805664 24.1684 0.805664C21.9439 0.805664 20.1406 2.60896 20.1406 4.83344C20.1406 7.05792 21.9439 8.86122 24.1684 8.86122Z"
          fill={props?.dotColor || '#FF645A'}
        />
      </g>
      <g display={props?.count ? 'none' : 'block'} clipPath="url(#clip0_221_762)">
        <path
          d="M26.4625 22.6603L26.1886 22.4187C25.4116 21.7264 24.7315 20.9325 24.1667 20.0584C23.5498 18.8521 23.1801 17.5348 23.0792 16.1837V12.2042C23.0845 10.0821 22.3147 8.03104 20.9144 6.43644C19.5142 4.84184 17.5798 3.81346 15.4747 3.54451V2.50535C15.4747 2.22013 15.3614 1.94659 15.1597 1.74491C14.9581 1.54323 14.6845 1.42993 14.3993 1.42993C14.1141 1.42993 13.8406 1.54323 13.6389 1.74491C13.4372 1.94659 13.3239 2.22013 13.3239 2.50535V3.56063C11.2377 3.84896 9.3267 4.88355 7.94482 6.4728C6.56293 8.06204 5.80381 10.0982 5.80806 12.2042V16.1837C5.70717 17.5348 5.33743 18.8521 4.72056 20.0584C4.16544 20.9303 3.49627 21.7241 2.73084 22.4187L2.45695 22.6603V24.932H26.4625V22.6603Z"
          fill="currentColor"
        />
        <path
          d="M12.3411 25.7778C12.4118 26.2885 12.6648 26.7563 13.0535 27.0949C13.4421 27.4335 13.9402 27.62 14.4557 27.62C14.9712 27.62 15.4693 27.4335 15.8579 27.0949C16.2466 26.7563 16.4996 26.2885 16.5703 25.7778H12.3411Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
};

export default NotificationIcon;
