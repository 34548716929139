import '@fontsource/noto-sans-kr/900.css';
import '@fontsource/noto-sans-kr/700.css';
import '@fontsource/noto-sans-kr/500.css';
import '@fontsource/roboto';
import { ChakraProvider, Button, Spinner } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import { Notifications } from 'src/components/Notifications/Notifications';
import theme from 'src/config/theme';
import { useLocale } from 'src/hooks/locale';
import { AuthProvider } from 'src/lib/auth';
import { queryClient } from 'src/lib/react-query';

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <Button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </Button>
    </div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const { locale, messages } = useLocale();
  // sentry docs
  //https://docs.sentry.io/platforms/javascript/guides/react/?_ga=2.65553965.2000426573.1643021339-221816500.1643021339&_gac=1.15244356.1643021683.Cj0KCQiAubmPBhCyARIsAJWNpiNgr7A7BPRR_xIDaRh_k7nFX6LA7hDoSLZNjGNTsJlIDwvk42juPhYaAgrkEALw_wcB
  Sentry.init({
    dsn: 'https://bfe81bf9fd9e4cc28adfa3f80fc7cbc9@o1125277.ingest.sentry.io/6164622',
    environment: `${process.env.REACT_APP_SENTRY_ENVIRONMENT}`,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost:3003',
          'bookingdev1.beforedent.com',
          'bookingapp.beforedent.com',
          /^\//,
        ],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE || '0.1'),
  });

  return (
    <ChakraProvider theme={theme}>
      <React.Suspense
        fallback={
          <div className="flex items-center justify-center w-screen h-screen">
            <Spinner size="xl" />
          </div>
        }
      >
        <IntlProvider locale={locale} defaultLocale="en" messages={messages?.default}>
          <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
            <HelmetProvider>
              <QueryClientProvider client={queryClient}>
                {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
                <Notifications />
                <AuthProvider>
                  <Router>{children}</Router>
                </AuthProvider>
              </QueryClientProvider>
            </HelmetProvider>
          </Sentry.ErrorBoundary>
        </IntlProvider>
      </React.Suspense>
    </ChakraProvider>
  );
};
