import Axios from 'axios';

import { API_URL } from 'src/config';
import { useNotificationStore } from 'src/stores/notifications';

export type FormError = {
  data: any;
};

export const axiosBare = Axios.create({
  baseURL: API_URL,
});

axiosBare.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    //const message = error.response?.data?.message || error?.message;
    if (error.response?.status == 401) {
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: 'Apologies',
        message: 'Please login again',
      });
    } else {
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: 'Apologies',
        message: 'There was an error.',
      });
    }
    if (error.response?.config?.method == 'get') {
      return null;
    }
    if (error.response?.status == 400) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error);
    }
  }
);
