import { Spinner, Flex } from '@chakra-ui/react';
import { useNavigate, useParams, useLocation, Navigate } from 'react-router-dom';

import { useAuth } from 'src/lib/auth';
import storage from 'src/utils/storage';

import { useClinic } from '../api/getClinic';
import { LoginForm } from '../components/LoginForm';
import { LoginFormJP } from '../components/LoginFormJP';
import { LoginFormTH } from '../components/LoginFormTH';
import { LoginLayout } from '../components/LoginLayout';

export const Login = ({ loginType = 'login' }: { loginType?: string }) => {
  const { user } = useAuth();
  const { country } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  const fromSearch = location.state?.from?.search || '?';
  console.log('Login fromsearch ', location.state?.from?.pathname);

  // Create a URLSearchParams object from the query string
  const searchParams = new URLSearchParams(`${location.state?.from?.search}`);

  // Create an empty object to store the parametersv
  const paramsObject: { [key: string]: string } = {};

  // Loop through the URLSearchParams and populate the object
  searchParams.forEach((value, key) => {
    paramsObject[key] = value;
  });

  console.log(searchParams);

  const paramsClinicId = paramsObject.clinic;
  if (parseInt(paramsClinicId) > 0) {
    storage.set('clinic', paramsClinicId);
  }
  console.log('clinicId', paramsClinicId);

  const clinicQuery = useClinic({
    clinic: paramsClinicId ? paramsClinicId : storage.get('clinic'),
    country: country ? country : '',
  });

  const isLoading = clinicQuery.isLoading || clinicQuery.isFetching;

  if (user) {
    return <Navigate to={from + fromSearch} replace={true} />;
  }

  const onLogin = () => {
    navigate(from + fromSearch, { replace: true });
  };

  if (isLoading) {
    return (
      <LoginLayout>
        <Flex w={'full'} justify={'center'} align={'center'} minH={'70vh'}>
          <Spinner size={'xl'} />
        </Flex>
      </LoginLayout>
    );
  }

  const clinicQueryCountry = clinicQuery.data?.country || '';
  const clinicQueryId =
    clinicQuery.data?.is_admin == true ? null : clinicQuery?.data?.dental_practice?.id;
  const clinicLINEChannel = clinicQuery.data?.line_channel?.type_id || '';

  // if this is a non-admin clinic, then forward it to the clinic country's login page
  // if not, then go to page with default beforedent parameters
  if (clinicQueryId) {
    if (country == 'general') {
      return (
        <LoginLayout>
          <LoginForm onSuccess={onLogin} />
        </LoginLayout>
      );
    } else {
      if (clinicQueryCountry.toLowerCase() == 'th') {
        return (
          <LoginLayout>
            <LoginFormTH
              loginType={loginType}
              clinicId={clinicQueryId}
              clinicLINEChannel={clinicLINEChannel}
              onSuccess={onLogin}
            />
          </LoginLayout>
        );
      } else if (clinicQueryCountry.toLowerCase() == 'jp') {
        return (
          <LoginLayout>
            <LoginFormJP
              loginType={loginType}
              clinicId={clinicQueryId}
              clinicLINEChannel={clinicLINEChannel}
              onSuccess={onLogin}
            />
          </LoginLayout>
        );
      } else {
        return (
          <LoginLayout>
            <LoginForm onSuccess={onLogin} />
          </LoginLayout>
        );
      }
    }
  } else {
    if (country == 'general') {
      return (
        <LoginLayout>
          <LoginForm onSuccess={onLogin} />
        </LoginLayout>
      );
    } else if (country == 'th') {
      return (
        <LoginLayout>
          <LoginFormTH
            loginType={loginType}
            clinicId={clinicQueryId}
            clinicLINEChannel={clinicLINEChannel}
            onSuccess={onLogin}
          />
        </LoginLayout>
      );
    } else {
      // if (country == 'ja') {
      return (
        <LoginLayout>
          <LoginFormJP
            loginType={loginType}
            clinicId={clinicQueryId}
            clinicLINEChannel={clinicLINEChannel}
            onSuccess={onLogin}
          />
        </LoginLayout>
      );
    }
  }
};
