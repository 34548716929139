import { Icon } from '@chakra-ui/react';

const CalendarIcon = (props: any) => {
  return (
    <Icon width="23px" height="23px" viewBox="0 0 23 23" color={'white'} {...props}>
      <path
        d="M2.51562 19.7656C2.51562 20.1632 2.83682 20.4844 3.23438 20.4844H19.7656C20.1632 20.4844 20.4844 20.1632 20.4844 19.7656V10.332H2.51562V19.7656ZM19.7656 4.13281H15.9922V2.69531C15.9922 2.59648 15.9113 2.51562 15.8125 2.51562H14.5547C14.4559 2.51562 14.375 2.59648 14.375 2.69531V4.13281H8.625V2.69531C8.625 2.59648 8.54414 2.51562 8.44531 2.51562H7.1875C7.08867 2.51562 7.00781 2.59648 7.00781 2.69531V4.13281H3.23438C2.83682 4.13281 2.51562 4.454 2.51562 4.85156V8.80469H20.4844V4.85156C20.4844 4.454 20.1632 4.13281 19.7656 4.13281Z"
        stroke={'currentColor'}
        fill={'currentColor'}
      />
    </Icon>
  );
};

export default CalendarIcon;
