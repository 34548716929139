import { Flex, Box, Text, Skeleton } from '@chakra-ui/react';
import { useParams } from 'react-router';

import { Logo } from 'src/Logo';
import storage from 'src/utils/storage';

import { useClinic } from '../api/getClinic';

export const LoginTitle = () => {
  const { country } = useParams();
  const clinic = storage.get('clinic');
  const clinicQuery = useClinic({
    clinic,
    country: country ? country : '',
    config: { refetchOnMount: false },
  });

  const isLoading = clinicQuery.isLoading || clinicQuery.isFetching;

  if (!parseInt(clinic)) {
    return (
      <Flex pt={[20]} pb={['35px', '45px']}>
        <Logo pointerEvents="none" />
      </Flex>
    );
  } else if (isLoading) {
    return (
      <Flex>
        <Skeleton h={'200px'} />
      </Flex>
    );
  } else {
    return (
      <Box mt={'8vh'}>
        <Flex direction={'column'} align={'center'} justify={'center'} pb={[8]}>
          <Flex align={['flex-end', 'center']}>
            <Logo pointerEvents="none" />
          </Flex>
          <Text
            pb={[0]}
            mt={'10px'}
            fontWeight={[500]}
            fontSize={['20px', '30px']}
            textAlign={'center'}
          >
            {clinicQuery.data?.name}
          </Text>
        </Flex>
      </Box>
    );
  }
};
