import { Icon } from '@chakra-ui/react';

const ClientsIcon = (props: any) => {
  return (
    <Icon width="28px" height="28px" viewBox="0 0 28 28" color={'white'} {...props}>
      <path
        d="M9.44998 14C10.6567 14 11.814 13.5206 12.6673 12.6673C13.5206 11.814 14 10.6567 14 9.45C14 8.24326 13.5206 7.08595 12.6673 6.23266C11.814 5.37937 10.6567 4.9 9.44998 4.9C8.24324 4.9 7.08593 5.37937 6.23264 6.23266C5.37935 7.08595 4.89998 8.24326 4.89998 9.45C4.89998 10.6567 5.37935 11.814 6.23264 12.6673C7.08593 13.5206 8.24324 14 9.44998 14ZM17.4118 21.203C18.1538 21.5068 19.0974 21.7 20.2986 21.7C25.8986 21.7 25.8986 17.5 25.8986 17.5C25.8986 16.9433 25.6775 16.4094 25.284 16.0156C24.8905 15.6218 24.3567 15.4004 23.8 15.4H17.3208C17.871 16.0664 18.2014 16.919 18.2014 17.85V18.3484C18.1985 18.4663 18.1906 18.584 18.1776 18.7012C18.0831 19.5759 17.8237 20.4248 17.4132 21.203H17.4118ZM23.8 10.5C23.8 11.4283 23.4312 12.3185 22.7748 12.9749C22.1185 13.6313 21.2282 14 20.3 14C19.3717 14 18.4815 13.6313 17.8251 12.9749C17.1687 12.3185 16.8 11.4283 16.8 10.5C16.8 9.57174 17.1687 8.6815 17.8251 8.02513C18.4815 7.36875 19.3717 7 20.3 7C21.2282 7 22.1185 7.36875 22.7748 8.02513C23.4312 8.6815 23.8 9.57174 23.8 10.5ZM2.09998 18.2C2.09998 17.4574 2.39497 16.7452 2.92008 16.2201C3.44518 15.695 4.15737 15.4 4.89998 15.4H14C14.7426 15.4 15.4548 15.695 15.9799 16.2201C16.505 16.7452 16.8 17.4574 16.8 18.2C16.8 18.2 16.8 23.8 9.44998 23.8C2.09998 23.8 2.09998 18.2 2.09998 18.2ZM18.2 18.3484L18.1958 18.438L18.1986 18.3484H18.2Z"
        fill={'currentColor'}
      />
    </Icon>
  );
};

export default ClientsIcon;
