import { FlexProps, Flex, Box, Link, Icon } from '@chakra-ui/react';
import { ReactText } from 'react';
import { IconType } from 'react-icons';
import { useLocation, Link as ReactRouterLink } from 'react-router-dom';

import { useAuth } from 'src/lib/auth';

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  route: string;
}

interface NavItemIconProps {
  selected: boolean;
  icon: IconType;
  color?: string;
}

const NavItemIcon = ({ icon, selected, color }: NavItemIconProps) => {
  return (
    <Flex pos={'relative'}>
      <Flex
        borderRadius={15}
        h={49}
        w={49}
        bg={selected ? 'brand.primary' : 'white'}
        _groupHover={{
          bg: 'brand.primary',
          color: 'white',
        }}
        align={'center'}
        justify={'center'}
        mr={5}
        zIndex={1}
      >
        {icon && (
          <Icon
            _groupHover={{ color: 'white' }}
            as={icon}
            color={color ? color : selected ? 'white' : '#009482'}
            h={23}
            w={23}
          />
        )}
      </Flex>
      <Flex
        display={selected ? 'flex' : 'none'}
        _groupHover={{
          display: 'flex',
        }}
        filter={'blur(10px)'}
        justify="center"
        pos={'absolute'}
        w={49}
      >
        <Box
          pos={'absolute'}
          top={4}
          background={'rgba(0, 87, 80, 0.49)'}
          borderRadius={15}
          h={39}
          w={39}
          zIndex={0}
        />
      </Flex>
    </Flex>
  );
};

export const NavItem = ({ icon, route, children, ...rest }: NavItemProps) => {
  const location = useLocation();
  const { logout } = useAuth();

  if (route == '/logout') {
    return (
      <Flex
        align="center"
        justify="start"
        py="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        fontSize={18}
        onClick={() => logout()}
        {...rest}
      >
        <NavItemIcon icon={icon} selected={false} color={'#FF645A'} />
        {children}
      </Flex>
    );
  } else {
    const selected = location.pathname.startsWith(route);
    return (
      <Link
        as={ReactRouterLink}
        to={route}
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}
      >
        <Flex
          align="center"
          justify="start"
          py={4}
          borderRadius="lg"
          role="group"
          cursor="pointer"
          fontSize={18}
          {...rest}
        >
          <NavItemIcon icon={icon} selected={selected} />
          {children}
        </Flex>
      </Link>
    );
  }
};
