import 'dayjs/locale/ko';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/inter/400.css';
import { AppRoutes } from 'src/routes';
import { AppProvider } from 'src/providers/app';

function App() {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}
export default App;
