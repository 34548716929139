import { Flex } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { useRef } from 'react';
import { useParams, Navigate } from 'react-router-dom';

import { useAuth } from 'src/lib/auth';
import storage from 'src/utils/storage';

import { useClinic } from '../api/getClinic';
import { LoginFormJP } from '../components/LoginFormJP';
import { LoginFormTH } from '../components/LoginFormTH';
import { LoginLayout } from '../components/LoginLayout';

export const RegisterClinic = () => {
  const { user } = useAuth();
  let { clinicId } = useParams();
  const reroute = useRef('/');
  //const location = useLocation();
  //const from = location.state?.from?.pathname || '/';
  //const fromSearch = location.state?.from?.search || '?';

  //const currentClinic = storage.get('clinic');
  console.log('clinicIdList ');
  if (clinicId == 'linecallback') {
    clinicId = storage.getLineClinicRegister();
  }

  // set Clinic ID from url to storage (for use in login and saving state)
  if (clinicId && clinicId != '') {
    storage.set('clinic', clinicId);
  }

  const clinicQuery = useClinic({
    clinic: clinicId ? clinicId : storage.get('clinic'),
  });

  const isLoading = clinicQuery.isLoading || clinicQuery.isFetching;

  // if user has already logged in, then forward to the home page
  // user needs to have clinic patient already
  if (user?.clinics) {
    // user needs to have patient in clincis
    const clinicIdList = user?.clinics?.map((clinic) => clinic.id);
    console.log('clinicIdList ', clinicIdList);
    if (clinicId && clinicIdList.includes(parseInt(clinicId || '0'))) {
      return <Navigate to={`/clinichome`} replace={true} />;
    }
  }

  // if there is a clinic already in storage, then forward to the home page?
  //if (currentClinic) {
  //  // show confirm pop up saying you want to switch clinics
  //  return <Navigate to={'/'} replace={true} />;
  //}

  const clinicQueryCountry = clinicQuery.data?.country || '';
  const clinicQueryId =
    clinicQuery.data?.is_admin == true ? null : clinicQuery?.data?.dental_practice?.id;
  const clinicLINEChannel = clinicQuery.data?.line_channel?.type_id || '';

  /** registering with clinic should lead to user information page */
  const onLogin = () => {
    console.log('onLogin /user?reg=y');
  };

  // if the clinic is still loading, then show a loading spinner
  if (isLoading) {
    return (
      <LoginLayout>
        <Flex w={'full'} justify={'center'} align={'center'} minH={'70vh'}>
          <Spinner size={'xl'} />
        </Flex>
      </LoginLayout>
    );
  }

  reroute.current = '/user?reg=y';

  // if clinic country is not found, then this is an error and should not happen
  //if (clinicQueryCountry == '') {
  //  console.log('blank clinic query country');
  //  return <Navigate to={'/'} replace={true} />;
  //}

  // if this is a non-admin clinic, then forward it to the clinic country's login page
  // if not, then go to page with default beforedent parameters
  if (clinicQueryCountry.toLowerCase() == 'th') {
    return (
      <LoginLayout>
        <LoginFormTH
          loginType={'register'}
          clinicId={clinicQueryId}
          clinicLINEChannel={clinicLINEChannel}
          onSuccess={onLogin}
        />
      </LoginLayout>
    );
  } else if (clinicQueryCountry.toLowerCase() == 'jp') {
    return (
      <LoginLayout>
        <LoginFormJP
          loginType={'register'}
          clinicId={clinicQueryId}
          clinicLINEChannel={clinicLINEChannel}
          onSuccess={onLogin}
        />
      </LoginLayout>
    );
  } else {
    return <></>;
  }
};
