import {
  Alert,
  AlertProps,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
const alertTypes = ['info', 'success', 'warning', 'error'] as const;

export type NotificationProps = {
  notification: {
    id: string;
    type: (typeof alertTypes)[number];
    title: string;
    message?: string;
    timeout?: number;
  };
  onDismiss: (id: string) => void;
};

const MotionAlert = motion<AlertProps>(Alert);

export const Notification = ({
  notification: { id, type, title, message, timeout },
  onDismiss,
}: NotificationProps) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onDismiss(id);
    }, timeout || 2500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MotionAlert
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      status={type}
      paddingLeft={18}
      paddingRight={[10, 50]}
      borderRadius={4}
    >
      <AlertIcon />
      <AlertTitle mr={3}>{title}</AlertTitle>
      <AlertDescription>{message}</AlertDescription>
      <CloseButton
        onClick={() => {
          onDismiss(id);
        }}
        position="absolute"
        right="8px"
        top="8px"
      />
    </MotionAlert>
  );
};
