import { Container, Box, Flex, Text, Heading, Link as ChakraLink } from '@chakra-ui/react';
import * as React from 'react';
import { Link } from 'react-router-dom';

import PrevArrowIcon from 'src/assets/icons/PrevArrowIcon';

import { Head } from '../Head';

type ContentLayoutProps = {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  headTitle?: string;
  noPadding?: boolean;
  prevUrl?: string;
  containerProps?: any;
  headerProps?: any;
};

export const ContentLayout = ({
  children,
  title,
  subtitle,
  headTitle,
  prevUrl = '',
  containerProps = {},
  headerProps = {},
}: ContentLayoutProps) => {
  return (
    <Box h="full" w="full" position="relative">
      <Head title={headTitle || title} subtitle={subtitle} />
      <Container h="full" w="full" px={[8, 5]} py={[8, 4]} pb={[0, 4]} {...containerProps}>
        <Flex direction="column" w="full" h="full" align="center">
          <Heading as="h2" w="full" size="lg" color="black" fontSize={'1.3em'} {...headerProps}>
            <ChakraLink as={Link} to={prevUrl}>
              <PrevArrowIcon color={'black'} mr={'1rem'} />
            </ChakraLink>
            {title}
          </Heading>
          {subtitle && (
            <Text w="full" color="darkGray" fontWeight={400} marginTop={1}>
              {subtitle}
            </Text>
          )}
          <Flex direction={'column'} grow={[1]} mt={5} width="full">
            {children}
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};
