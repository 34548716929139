import { useQuery } from 'react-query';

import { axios } from 'src/lib/axios';
import { QueryConfig } from 'src/lib/react-query';

type ClinicCalendarNameQuery = {
  id: number;
};

export const getQuery = (calendarName?: string): Promise<ClinicCalendarNameQuery> => {
  return axios.get(`/sch/clinics/name/${calendarName}`);
};

type useClinicOptions = {
  calendarName?: string;
  config?: QueryConfig<typeof getQuery>;
};

export const useClinicCalendarName = ({ calendarName, config }: useClinicOptions) => {
  return useQuery<ClinicCalendarNameQuery>({
    ...config,
    enabled: !!calendarName,
    queryKey: ['clinicCalendarName', calendarName],
    queryFn: () => getQuery(calendarName),
  });
};
