import { Flex, VStack, Grid, Button } from '@chakra-ui/react';
import { Field, Form } from 'react-final-form';
import { useIntl, FormattedMessage } from 'react-intl';

import { InputField } from 'src/components/Form/InputField';
import { useAuth } from 'src/lib/auth';
import storage from 'src/utils/storage';

import { LoginCredentials } from '../api/login';

export const LoginForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const { login, isLoggingIn } = useAuth();
  const { formatMessage } = useIntl();

  const handleSubmit = async (values: { email: string; password: string; country?: string }) => {
    console.debug(values, login, onSuccess);
    if (storage.get('country') != '') {
      values.country = storage.get('country');
    }
    await login({ provider: 'email', ...values } as LoginCredentials);
    onSuccess();
  };

  return (
    <Grid height="full" p={3} w="full">
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Flex direction={'column'} justify={['space-between', 'flex-start']}>
              <VStack spacing={8} w="full" px={[6]} pt={[0, 20]}>
                <Field name="email">
                  {({ input, meta }) => (
                    <InputField
                      label={formatMessage({
                        id: 'login.form.label.email',
                        description: 'loginform input label email',
                        defaultMessage: 'email',
                      })}
                      input={input}
                      meta={meta}
                      type="email"
                    />
                  )}
                </Field>
                <Field name="password">
                  {({ input, meta }) => (
                    <InputField
                      label={formatMessage({
                        id: 'login.form.label.password',
                        description: 'loginform input label password',
                        defaultMessage: 'password',
                      })}
                      input={input}
                      meta={meta}
                      type="password"
                    />
                  )}
                </Field>
              </VStack>
              <Flex w="100%" justify={'flex-end'} py={6} px={8}>
                <Button
                  variant={'primary'}
                  h="50px"
                  type="submit"
                  color="white"
                  w="230px"
                  size="xl"
                  isLoading={submitting || isLoggingIn}
                  flex="1"
                >
                  <FormattedMessage
                    id="login.form.button.previous"
                    defaultMessage="login"
                    description="loginform login button"
                  />
                </Button>
              </Flex>
            </Flex>
          </form>
        )}
      />
    </Grid>
  );
};
