import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyDAQPuKVOjGsyU9oSIwr7gYD3ZU0vT5YD8',
  authDomain: 'beforedent-aligners.firebaseapp.com',
  projectId: 'beforedent-aligners',
  storageBucket: 'beforedent-aligners.appspot.com',
  messagingSenderId: '1022183573842',
  appId: '1:1022183573842:web:53b5ce1a61db5aab2f528b',
  measurementId: 'G-WGM7RGCYQR',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const logFirebaseEvent = (event, data = {}) => {
  if (event === 'photo_assessment_upload') {
    logEvent(analytics, event, data);
  } else if (event === 'photo_assessment_remove') {
    logEvent(analytics, event, data);
  } else if (event === 'photo_assessment_upload_completed') {
    logEvent(analytics, event, data);
  }
};
