import { Icon } from '@chakra-ui/react';

const LogoutIcon = (props: any) => {
  return (
    <Icon width="19px" height="19px" viewBox="0 0 19 19" {...props}>
      <g clipPath="url(#clip0_69_3033)">
        <path
          d="M2.84995 2.85001H10.45V0.950012H2.84995C2.34604 0.950012 1.86277 1.15019 1.50645 1.50651C1.15013 1.86283 0.949951 2.3461 0.949951 2.85001V16.15C0.949951 16.6539 1.15013 17.1372 1.50645 17.4935C1.86277 17.8498 2.34604 18.05 2.84995 18.05H10.45V16.15H2.84995V2.85001Z"
          fill={'currentColor'}
        />
        <path
          d="M12.35 4.75V8.55H4.75V10.45H12.35V14.25L18.05 9.5L12.35 4.75Z"
          fill={'currentColor'}
        />
      </g>
    </Icon>
  );
};

export default LogoutIcon;
