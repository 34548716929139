import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Textarea } from '@chakra-ui/textarea';
import { Select } from 'chakra-react-select';
import { FormattedMessage } from 'react-intl';
import PhoneInput from 'react-phone-number-input/input';

import SelectDropdownIcon from 'src/assets/icons/SelectDropdownIcon';
import { isFieldErrorfromMeta, getErrorMessage } from 'src/utils/form';

//https://github.com/csandman/chakra-react-select/tree/v3
// react select chakra v3 for chakra v1

export type SelectOption = {
  label: string;
  value: string;
  isFixed?: boolean;
};

export type FieldErrorProps = {
  error: boolean;
  touched: boolean;
  submitError: any;
  dirtySinceLastSubmit: boolean;
};

export type InputFieldProps = {
  input: any;
  meta: any;
  isDisabled?: boolean;
  label?: string | null;
  type?: string;
  errorMessage?: string | null;
  placeholder?: string | null;
  max?: string | null;
};

export type TextareaFieldProps = {
  input: any;
  meta: any;
  isDisabled?: boolean;
  label?: string | null;
  type?: string;
  errorMessage?: string | null;
  placeholder?: string | null;
  max?: string | null;
};

export type SelectFieldProps = {
  input: any;
  meta: any;
  options: SelectOption[];
  isDisabled?: boolean;
  label?: string | null;
  errorMessage?: string | null;
  placeholder?: string | null;
};

export type PhoneInputFieldProps = {
  input: any;
  meta: any;
  isDisabled?: boolean;
  country?: string | null;
  label?: string;
  errorMessage?: string | null;
  placeholder?: string | null;
};

export const FormattedErrorMessage = ({ error }: { error: string }) => {
  if (error && error.startsWith('form.error.')) {
    return (
      <FormErrorMessage>
        <FormattedMessage id={'form.error.required'} defaultMessage={'required'} />
      </FormErrorMessage>
    );
  }
  return <FormErrorMessage>{error}</FormErrorMessage>;
};

export const SelectField = ({
  input,
  meta,
  options,
  isDisabled = false,
  label = null,
  placeholder = null,
}: SelectFieldProps) => (
  <FormControl id={input.name} isInvalid={isFieldErrorfromMeta(meta)} px="2px">
    {label && <FormLabel>{label}</FormLabel>}
    <Select
      {...input}
      disabled={isDisabled}
      options={options}
      placeholder={placeholder || label}
      value={options.find((option) => option?.value === input.value)}
      onChange={(option: any) => input.onChange(option?.value)}
      icon={<SelectDropdownIcon />}
      styles={{
        placeholder: (styles) => ({ ...styles, color: '#7B8887' }),
      }}
      chakraStyles={{
        dropdownIndicator: (provided: any) => ({
          ...provided,
          background: 'transparent',
          color: '#1B2B29',
        }),
        indicatorSeparator: (provided: any) => ({
          ...provided,
          border: 'none',
          borderColor: 'transparent',
        }),
        control: (provided: any) => ({
          ...provided,
          borderColor: 'rgba(123, 136, 135, 0.5)',
        }),
        placeholder: (provided: any) => ({
          ...provided,
          color: '#7B8887',
        }),
      }}
    />
    <FormattedErrorMessage error={getErrorMessage(meta)} />
  </FormControl>
);

export const AsyncSelectField = ({
  input,
  meta,
  options,
  isDisabled = false,
  label = null,
  placeholder = null,
}: SelectFieldProps) => (
  <FormControl id={input.name} isInvalid={isFieldErrorfromMeta(meta)} px="2px">
    {label && <FormLabel>{label}</FormLabel>}
    <Select
      {...input}
      disabled={isDisabled}
      options={options}
      placeholder={placeholder || label}
      icon={<SelectDropdownIcon />}
      styles={{
        placeholder: (styles) => ({ ...styles, color: '#7B8887' }),
      }}
      chakraStyles={{
        dropdownIndicator: (provided: any) => ({
          ...provided,
          background: 'transparent',
          color: '#1B2B29',
        }),
        indicatorSeparator: (provided: any) => ({
          ...provided,
          border: 'none',
          borderColor: 'transparent',
        }),
        control: (provided: any) => ({
          ...provided,
          borderColor: 'rgba(123, 136, 135, 0.5)',
        }),
        placeholder: (provided: any) => ({
          ...provided,
          color: '#7B8887',
        }),
      }}
    />
    <FormattedErrorMessage error={getErrorMessage(meta)} />
  </FormControl>
);

export const InputField = ({
  input,
  meta,
  isDisabled = false,
  label = null,
  placeholder = null,
  max = null,
  type = 'text',
}: InputFieldProps) => (
  <FormControl
    id={input.name}
    isInvalid={isFieldErrorfromMeta(meta)}
    px="2px"
    borderColor={'rgba(123, 136, 135, 0.5)'}
  >
    {label && <FormLabel>{label}</FormLabel>}
    <Input
      {...input}
      disabled={isDisabled}
      type={type}
      placeholder={placeholder || label}
      max={max}
    />
    <FormattedErrorMessage error={getErrorMessage(meta)} />
  </FormControl>
);

export const TextareaField = ({
  input,
  meta,
  isDisabled = false,
  label = null,
  placeholder = null,
  max = null,
}: TextareaFieldProps) => (
  <FormControl
    id={input.name}
    isInvalid={isFieldErrorfromMeta(meta)}
    px="2px"
    borderColor={'rgba(123, 136, 135, 0.5)'}
  >
    {label && <FormLabel>{label}</FormLabel>}
    <Textarea {...input} disabled={isDisabled} placeholder={placeholder || label} max={max} />
    <FormattedErrorMessage error={getErrorMessage(meta)} />
  </FormControl>
);

export const PhoneInputField = ({
  input,
  meta,
  isDisabled = false,
  label,
  country = null,
  placeholder = null,
}: PhoneInputFieldProps) => (
  <FormControl
    id={input.name}
    isInvalid={isFieldErrorfromMeta(meta)}
    px="2px"
    borderColor={'rgba(123, 136, 135, 0.5)'}
  >
    <FormLabel>{label}</FormLabel>
    <PhoneInput
      {...input}
      disabled={isDisabled}
      defaultCountry={country}
      placeholder={placeholder || label}
      inputComponent={Input}
    />
    <FormattedErrorMessage error={getErrorMessage(meta)} />
  </FormControl>
);
