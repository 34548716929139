import { IconButton } from '@chakra-ui/button';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/menu';
import { Flex, Box, VStack, Image } from '@chakra-ui/react';
import * as React from 'react';
import { FaGlobe } from 'react-icons/fa';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import homeImage from 'src/assets/images/HomeHeroImage.png';
import { useNavigatorLanguage } from 'src/hooks/navigatorLanguage';
import storage from 'src/utils/storage';

import { useClinic } from '../api/getClinic';

import { LoginTitle } from './LoginTitle';
type LayoutProps = {
  children: React.ReactNode;
};

const LanguageButton = ({
  currentLanguage,
  onItemClick,
}: {
  currentLanguage: string;
  onItemClick: (lang: string) => void;
}) => {
  // if 'register' is in the URL, hide the language button
  const { country } = useParams();
  const clinic = storage.get('clinic');
  const clinicQuery = useClinic({
    clinic,
    country: country ? country : '',
    config: { refetchOnMount: false },
  });
  const hideLanguageButton = window.location.href.includes('register');

  const handleBeforedentClick = () => {
    storage.clearClinic();
    onItemClick(currentLanguage);
  };
  const isLoading = clinicQuery.isLoading || clinicQuery.isFetching;

  if (isLoading) {
    return <></>;
  } else if (clinicQuery.data?.is_admin == true) {
    return (
      <Box
        display={hideLanguageButton ? 'none' : 'box'}
        position="absolute"
        bottom="0"
        right="0"
        margin={[5, 10]}
      >
        <Menu placement="top-end" computePositionOnMount={true} matchWidth={true}>
          <MenuButton as={IconButton} icon={<FaGlobe />} autoSelect={false} />
          <MenuList>
            <MenuItem isDisabled={country === 'th'} onClick={() => onItemClick('th')}>
              {'ประเทศไทย'}
            </MenuItem>
            <MenuItem isDisabled={country === 'ja'} onClick={() => onItemClick('ja')}>
              {'日本'}
            </MenuItem>
            <MenuItem onClick={handleBeforedentClick}>{'Beforedent'}</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    );
  } else {
    return <></>;
  }
};

export const LoginLayout = ({ children }: LayoutProps) => {
  const { language, setNavigatorLanguage } = useNavigatorLanguage();
  const navigate = useNavigate();

  const setLoginCountry = (val: string) => {
    storage.setCountry(val == 'ja' ? 'jp' : val);
    setNavigatorLanguage(val);
    navigate(`/auth/login/${val}`);
  };

  return (
    <>
      <Flex height="full" direction={'column'} align={['center']}>
        <LoginTitle />
        <Flex py={['10px', '30px']} px={['50px', '100px']} maxW={['336px', '500px']}>
          <Image src={homeImage} alt="logo" />
        </Flex>
        <VStack w="full" h="full" mt={['20px', 0]} spacing={10}>
          <Box w="full">{children}</Box>
        </VStack>
        <LanguageButton currentLanguage={language} onItemClick={setLoginCountry} />
      </Flex>
    </>
  );
};
