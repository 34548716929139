import { Box, Button, Flex, Heading, Spinner } from '@chakra-ui/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import NotificationIcon from 'src/assets/icons/NotificationIcon';
import { Sidebar } from 'src/components/Sidebar';

type ContentLayoutWithSidebarProps = {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  headTitle?: string;
};

type ContentLayoutHeaderProps = {
  title: string;
  titleComponent?: React.ReactNode;
};

const ContentLayoutHeader = ({ title, titleComponent }: ContentLayoutHeaderProps) => {
  const unreadCount = 0;

  return (
    <Box w={'full'} pb={4}>
      <Flex justify="space-between" align={'end'}>
        <Flex justify={'start'} align={'end'}>
          {titleComponent ? (
            titleComponent
          ) : (
            <Heading fontSize={'24px'} mr={3}>
              {title}
            </Heading>
          )}
        </Flex>
        <Flex justify={'end'} align={'center'}>
          <Box mr={15}>
            <NotificationIcon count={unreadCount} />
          </Box>
          <Button variant={'primary'} size={'lg'}>
            <FormattedMessage
              id="header.appointment.add.button.label"
              description="calendar header appointment button"
              defaultMessage="+ Appointment"
            />
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export const ContentLayoutWithSidebar = ({ children, title }: ContentLayoutWithSidebarProps) => {
  return (
    <Box h="full" w="full" position="relative">
      <Flex h="full" w="full" px={[0, 0]} py={[4, 0]} pb={[0, 0]}>
        <Sidebar onClose={() => {}} />
        <Box my={79} borderRight="1px" borderRightColor={'#E0E9E8'}></Box>
        <Flex h="full" w="full" direction={'column'} px={25} pt={45}>
          <ContentLayoutHeader title={title} />
          {children}
        </Flex>
      </Flex>
    </Box>
  );
};

export const SuspenseContentLayoutWithSidebar = () => {
  return (
    <Box h="full" w="full" position="relative">
      <Flex h="full" w="full" px={[0, 0]} py={[4, 4]} pb={[0, 4]}>
        <Sidebar onClose={() => {}} />
        <Box my={79} borderRight="1px" borderRightColor={'#E0E9E8'}></Box>
        <Flex direction="column" w="full" h="full" align="center" justify="center" grow={[1]}>
          <Spinner thickness="4px" speed="0.65s" size="xl" />
        </Flex>
      </Flex>
    </Box>
  );
};
