import * as React from 'react';

//import storage from 'src/utils/storage';

import { getUserCountry } from './locale';

export const useNavigatorLanguage = () => {
  const [locale, setCurrent] = React.useState(getUserCountry());

  if (locale == null) {
    let newLocale = 'en';
    for (let i = 0; i < navigator.languages.length - 1; i++) {
      if (navigator.languages[i].toLowerCase().startsWith('en')) {
        newLocale = 'en';
        break;
      } else if (navigator.languages[i].toLowerCase().startsWith('th')) {
        newLocale = 'th';
        break;
      } else if (navigator.languages[i].toLowerCase().startsWith('ja')) {
        newLocale = 'ja';
        break;
      }
    }
    setCurrent(newLocale);
  }

  const setNavigatorLanguage = React.useCallback((val) => {
    setCurrent(val);
  }, []);

  return { language: locale, setNavigatorLanguage };
};
