import { Flex, Spinner } from '@chakra-ui/react';
import { Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { MainLayout } from 'src/components/Layout';
import { SuspenseContentLayoutWithSidebar } from 'src/components/Layout/ContentLayoutWithSidebar';
import { lazyImport } from 'src/utils/lazyImport';

import { RequireAuth } from './index';

const { AppointmentRoutes } = lazyImport(
  () => import('src/features/appointments'),
  'AppointmentRoutes'
);

const { CalendarRoutes } = lazyImport(() => import('src/features/calendar'), 'CalendarRoutes');
const { ClientRoutes } = lazyImport(() => import('src/features/clients'), 'ClientRoutes');
const { SettingsRoutes } = lazyImport(() => import('src/features/settings'), 'SettingsRoutes');
const { ClinicRoutes } = lazyImport(() => import('src/features/clinichome'), 'ClinicRoutes');

const { UserRoutes } = lazyImport(() => import('src/features/users'), 'UserRoutes');
const { HomeRoute } = lazyImport(() => import('src/features/home'), 'HomeRoute');

const App = () => {
  return (
    <MainLayout>
      <Suspense fallback={<SuspenseContentLayoutWithSidebar />}>
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

const ClientApp = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <Flex h="full" w="full" justify="center" align="center">
            <Spinner thickness="4px" speed="0.65s" size="xl" />
          </Flex>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/clinic/',
    element: <App />,
    children: [
      {
        path: '/clinic/calendar/*',
        element: (
          <RequireAuth>
            <CalendarRoutes />
          </RequireAuth>
        ),
      },
      {
        path: '/clinic/clients/*',
        element: (
          <RequireAuth>
            <ClientRoutes />
          </RequireAuth>
        ),
      },
      {
        path: '/clinic/settings/*',
        element: (
          <RequireAuth>
            <SettingsRoutes />
          </RequireAuth>
        ),
      },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
  {
    path: '/',
    element: <ClientApp />,
    children: [
      {
        index: true,
        element: (
          <RequireAuth>
            <HomeRoute />
          </RequireAuth>
        ),
      },
      {
        path: '/clinichome/*',
        element: (
          <RequireAuth>
            <ClinicRoutes />
          </RequireAuth>
        ),
      },
      {
        path: '/appointments/*',
        element: (
          <RequireAuth>
            <AppointmentRoutes />
          </RequireAuth>
        ),
      },
      {
        path: '/user/*',
        element: (
          <RequireAuth>
            <UserRoutes />
          </RequireAuth>
        ),
      },
    ],
  },
];
