import { VStack, Center } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';

import { useNotificationStore } from 'src/stores/notifications';

import { Notification } from './Notification';

export const Notifications = () => {
  const { notifications, dismissNotification } = useNotificationStore();

  return (
    <Center w="full" position={'absolute'}>
      <VStack width={'100%'} spacing={3} zIndex="10" marginTop={15} mx={3}>
        <AnimatePresence>
          {notifications.map((notification) => (
            <Notification
              key={notification.id}
              notification={notification}
              onDismiss={dismissNotification}
            />
          ))}
        </AnimatePresence>
      </VStack>
    </Center>
  );
};
