import { Input } from '@chakra-ui/input';
import {
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
} from '@chakra-ui/modal';
import { Grid, Flex, Text, Button, FormControl } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import PhoneInput from 'react-phone-number-input/input';
//import { useNavigate } from 'react-router-dom';

export const PhoneNumberModal = ({
  isOpen,
  onClose,
  isLoggingIn,
  phoneError,
  handleLoginSkipPhone,
  handleLogin,
  handlePhoneChange,
}: {
  isOpen: boolean;
  onClose: () => void;
  isLoggingIn: boolean;
  phoneError: boolean;
  handleLoginSkipPhone: () => void;
  handleLogin: () => void;
  handlePhoneChange: (val: string) => void;
}) => {
  const { formatMessage } = useIntl();
  //const navigate = useNavigate();

  //const handleAlreadyRegistered = () => {
  //  onClose();
  //  navigate(`/auth/login/ja`);
  //};

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      placement={'bottom'}
      size={'md'}
    >
      <DrawerOverlay />
      <DrawerContent minH={['40vh']} p={['20px']} pb={[0]}>
        <DrawerHeader>
          <FormattedMessage
            id={'linelogin.phone.popup.title'}
            defaultMessage={'Confirm your phone number'}
          />
        </DrawerHeader>
        <DrawerBody>
          <Text py={['20px']} pt={['10px']}>
            <FormattedMessage
              id={'linelogin.phone.popup.description'}
              defaultMessage={'Add the phone number used to make your appointment.'}
            />
          </Text>
          <FormControl isInvalid={phoneError}>
            <PhoneInput
              name={'phone_number'}
              defaultCountry={'JP'}
              placeholder={formatMessage({
                id: 'linelogin.phone.format.placeholder',
                defaultMessage: 'Enter phone number',
              })}
              inputComponent={Input}
              onChange={handlePhoneChange}
            />
          </FormControl>
        </DrawerBody>
        <DrawerFooter pb={['20px']}>
          <Flex w={['full']} direction={['column']}>
            <Grid w={['full']} gridTemplateColumns={['1fr 1fr']} gridGap={['20px']}>
              <Button isDisabled={isLoggingIn} variant={'outline'} onClick={handleLoginSkipPhone}>
                <FormattedMessage
                  id={'linelogin.phone.skip.button.label'}
                  defaultMessage={'Skip'}
                />
              </Button>
              <Button isDisabled={isLoggingIn} variant={'primary'} onClick={handleLogin}>
                <FormattedMessage
                  id={'linelogin.phone.add.button.label'}
                  defaultMessage={'Submit'}
                />
              </Button>
            </Grid>
            <Grid pt={['20px']}>
              {/*<Button onClick={handleLoginSkipPhone} isLoading={isLoggingIn} variant={'link'}>
                <FormattedMessage
                  id={'linelogin.phone.alreadyregistered.button.label'}
                  defaultMessage={'I have registered already'}
                />
            </Button>*/}
            </Grid>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
