import { Modal, ModalOverlay, ModalContent, Flex, Spinner } from '@chakra-ui/react';
import { setUserId, setUserProperties } from 'firebase/analytics';
import { initReactQueryAuth } from 'react-query-auth';

import {
  login,
  loginWithLine,
  loginWithLineClinic,
  registerWithLineClinic,
  LoginCredentials,
  LoginCredentialsSocialLine,
  getUser,
  UserResponse,
  AuthUser,
} from 'src/features/auth';
import { consoleLog } from 'src/utils/consoleLog';
import storage from 'src/utils/storage';

import { analytics } from './firebaseAnalytics';

interface Error {
  message: string;
}
//
async function handleTokenResponse(data: UserResponse) {
  const { key, active } = data;
  if (active) {
    storage.setToken(key);
    return key;
  }
  // if not active, show not active modal
  // if wrong country? i dont think theres a wrong country, it will just show country
}
// load data from user
async function loadUser() {
  if (storage.getToken()) {
    try {
      const data = await getUser();
      if (data?.country) {
        storage.setCountry(data.country.toLowerCase());
      }
      if (data?.locale) {
        storage.setLocale(data.locale.toLowerCase());
      }
      if (data?.id) {
        setUserId(analytics, data.id);
        setUserProperties(analytics, { admining: data?.admining_status });
      }
      return data;
    } catch (error) {
      consoleLog('error');
    }
  }
  return null;
}

async function loginFn(data: LoginCredentialsSocialLine | LoginCredentials) {
  if (data?.provider.toLowerCase() === 'line') {
    // LINE login
    if ((data as LoginCredentialsSocialLine)?.clinic) {
      // with Clinic
      const response = await loginWithLineClinic(data as LoginCredentialsSocialLine);
      await handleTokenResponse(response);
    } else {
      // with Beforedent
      const response = await loginWithLine(data as LoginCredentialsSocialLine);
      await handleTokenResponse(response);
    }
  } else if (data?.provider.toLowerCase() === 'line_register') {
    // LINE register
    if ((data as LoginCredentialsSocialLine)?.clinic) {
      // with Clinic
      const response = await registerWithLineClinic(data as LoginCredentialsSocialLine);
      await handleTokenResponse(response);
    } else {
      // with Beforedent
      const response = await loginWithLine(data as LoginCredentialsSocialLine);
      await handleTokenResponse(response);
    }
  } else {
    const response = await login(data as LoginCredentials);
    await handleTokenResponse(response);
  }
  try {
    const user = await loadUser();
    return user;
  } catch (error) {
    return null;
  }
}

async function registerFn(data: LoginCredentialsSocialLine | LoginCredentials) {
  if (data?.provider.toLowerCase() === 'line') {
    const response = await loginWithLine(data as LoginCredentialsSocialLine);
    await handleTokenResponse(response);
  } else {
    const response = await login(data as LoginCredentials);
    await handleTokenResponse(response);
  }

  try {
    const user = await loadUser();
    return user;
  } catch (error) {
    return null;
  }
}

async function logoutFn() {
  storage.clearToken();
  window.location.assign(window.location.origin as unknown as string);
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <>
        <Modal isOpen={true} onClose={() => null} isCentered>
          <ModalOverlay />
          <ModalContent bg="none" boxShadow="none">
            <Flex align="center" justify="Center">
              <Spinner thickness="4px" speed="0.65s" size="xl" />
            </Flex>
          </ModalContent>
        </Modal>
      </>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  AuthUser | null,
  Error,
  LoginCredentialsSocialLine | LoginCredentials
>(authConfig);
