import { Flex, Box, BoxProps, CloseButton } from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';

import CalendarIcon from 'src/assets/icons/CalendarIcon';
import ClientIcon from 'src/assets/icons/ClientsIcon';
import LogoutIcon from 'src/assets/icons/LogoutIcon';
import SettingIcon from 'src/assets/icons/SettingsIcon';
import { Logo } from 'src/Logo';

import { NavItem } from './NavItem';
import { UserProfileItem } from './UserProfileItem';

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

interface LinkItemProps {
  name: any; //ReactIntlFormattedMessageProps;
  icon: any;
  route: string;
}

const LinkItems: Array<LinkItemProps> = [
  {
    name: (
      <FormattedMessage
        id="sidebar.navItem.calendar.label"
        defaultMessage="Calendar"
        description="sidebar item calendar label"
      />
    ),
    icon: CalendarIcon,
    route: '/calendar',
  },
  {
    name: (
      <FormattedMessage
        id="sidebar.navItem.clients.label"
        defaultMessage="Patients"
        description="sidebar item clients label"
      />
    ),
    icon: ClientIcon,
    route: '/clients',
  },
  {
    name: (
      <FormattedMessage
        id="sidebar.navItem.settings.label"
        defaultMessage="Settings"
        description="sidebar item settings label"
      />
    ),
    icon: SettingIcon,
    route: '/settings',
  },
];

export const Sidebar = ({ onClose, ...rest }: SidebarProps) => {
  const { formatMessage } = useIntl();

  return (
    <Box
      bg={'transparent'}
      w={{ base: 'full', md: 300 }}
      minWidth={{ base: 'full', md: 300 }}
      pl={[0, 59]}
      pr={[0, 4]}
      pt={14}
      pb={38}
      h="full"
      {...rest}
    >
      <Flex w={'full'} h={'full'} direction={'column'} justify={'space-between'}>
        <Flex w={'full'} alignItems="center" justifyContent="space-between">
          <Logo pointerEvents="none" w={[165, 165]} />
          <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
        </Flex>
        <Box mt={8}>
          <UserProfileItem />
        </Box>
        <Flex w={'full'} h={'full'} direction={'column'} justify={'space-between'} mt={88}>
          <Box>
            {LinkItems.map((link) => (
              <NavItem key={link.route} icon={link.icon} route={link.route}>
                {link.name}
              </NavItem>
            ))}
          </Box>
          <Box>
            <NavItem icon={LogoutIcon} route={'/logout'}>
              {formatMessage({
                id: 'sidebar.navItem.logout.label',
                description: 'sidebar item logout label',
                defaultMessage: 'Logout',
              })}
            </NavItem>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
