import { Helmet } from 'react-helmet-async';

type HeadProps = {
  title?: string;
  description?: string;
  subtitle?: string;
};

export const Head = ({ title = '', subtitle = '', description = '' }: HeadProps = {}) => {
  return (
    <Helmet
      title={title ? `Plover Scheduling | ${title} ${subtitle ? `- ${subtitle}` : ''}` : undefined}
      defaultTitle="Plover Scheduling"
    >
      <meta name="description" content={description} />
    </Helmet>
  );
};
