import { Navigate, useRoutes, useLocation } from 'react-router-dom';

//import { Landing } from 'src/features/misc';

import { useAuth } from 'src/lib/auth';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth/login" state={{ from: location }} replace={true} />;
  }

  //if (auth.user?.country === 'TH' && auth.user?.line_status === false) {
  //  // pop up a add friend button here.
  //  return <Navigate to="/social/line/addchannel" state={{ from: location }} />;
  //}

  return children;
}

export const AppRoutes = () => {
  //const auth = useAuth();
  //const commonRoutes = [{ path: '/landing', element: <Landing /> }];

  // Import the functions you need from the SDKs you need
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional

  //const routes = auth.user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...publicRoutes, ...protectedRoutes]);

  return <>{element}</>;
};
