import { Flex, Heading, Text, Image } from '@chakra-ui/react';

export const UserProfileItem = () => {
  return (
    <Flex>
      <Image
        borderRadius="full"
        boxSize={74}
        src="https://bit.ly/dan-abramov"
        alt="Dan Abramov"
        mr={3}
      />
      <Flex direction={'column'} justify={'center'}>
        <Heading fontSize={18} fontWeight={500}>
          {'Doctor Name'}
        </Heading>
        <Text fontSize={12} color={'subtitle'} fontFamily={'Inter'}>
          {'Role (Dentist)'}
        </Text>
      </Flex>
    </Flex>
  );
};
