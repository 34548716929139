import { Flex } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { useParams, Route, Routes, Navigate } from 'react-router-dom';

import storage from 'src/utils/storage';

import { useClinicCalendarName } from '../api/getClinicFromName';

import { Login } from './Login';
import { RegisterClinic } from './RegisterClinic';

const LoginReRoute = () => {
  const storageCountry = storage.getCountry();
  if (storageCountry) {
    return <Navigate to={`/auth/login/${storageCountry}`} />;
  }
  let newCountry = 'jp';
  for (let i = 0; i < navigator.languages.length - 1; i++) {
    if (navigator.languages[i].toLowerCase().startsWith('th')) {
      newCountry = 'th';
      break;
    } else if (navigator.languages[i].toLowerCase().startsWith('ja')) {
      newCountry = 'jp';
      break;
    }
  }
  return <Navigate to={`/auth/login/${newCountry}`} />;
};

const BeforedentClinicReRoute = () => {
  const { country } = useParams();
  storage.clearClinic();

  if (country) {
    storage.setCountry(country);
    return <Navigate to={`/auth/login/${country}`} />;
  } else {
    return <Navigate to={`/auth/login`} />;
  }
};

const ClinicReRoute = () => {
  const { calendarName } = useParams();
  const clinicCalendarQuery = useClinicCalendarName({ calendarName: calendarName });

  if (
    clinicCalendarQuery.isLoading ||
    clinicCalendarQuery.isFetching ||
    !clinicCalendarQuery?.data
  ) {
    return (
      <Flex w={'full'} justify={'center'} align={'center'} minH={'70vh'}>
        <Spinner size={'xl'} />
      </Flex>
    );
  }

  if (clinicCalendarQuery?.data?.id) {
    return <Navigate to={`/auth/register/clinic/${clinicCalendarQuery?.data?.id}`} />;
  } else {
    return <Navigate to={`/auth/login`} />;
  }
};

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="clinic/calendar/:calendarName" element={<ClinicReRoute />} />
      <Route path="register/clinic/:clinicId" element={<RegisterClinic />} />
      <Route path="register/country/:country" element={<Login loginType={'register'} />} />
      <Route path="login/beforedent/:country" element={<BeforedentClinicReRoute />} />
      <Route path="login/:country" element={<Login />} />
      <Route path="login/" element={<LoginReRoute />} />
      <Route path="*" element={<Navigate to={'login/'} />} />
    </Routes>
  );
};
