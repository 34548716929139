export const isFieldErrorfromMeta = (meta: any) =>
  (meta.error && meta.touched) || ((meta.submitError && !meta.dirtySinceLastSubmit) as boolean);

export function getErrorMessage(meta: any) {
  if (meta.error) {
    return meta.error;
  } else if (meta.submitError && Array.isArray(meta.submitError)) {
    return meta.submitError[0];
  }
  return '';
}
export const required = (value: any) => (value ? undefined : 'form.error.required');
export const mustBeNumber = (value: number) => (isNaN(value) ? 'number' : undefined);
export const minValue = (min: number) => (value: number) =>
  isNaN(value) || value >= min ? undefined : `>${min}`;
export const composeValidators =
  (...validators: any[]) =>
  (value: any) =>
    validators.reduce((error, validator) => error || validator(value), undefined);
