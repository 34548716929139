import dayjs from 'dayjs';
import * as React from 'react';

import 'dayjs/locale/ja';
import 'dayjs/locale/th';

import storage from 'src/utils/storage';

async function loadLocaleData(locale: string | null | undefined) {
  console.log('loadLocaleData' + locale);
  if (!locale) {
    locale = storage.getLocale() ? storage.getLocale() : navigator.language.toLowerCase(); // Get user's browser language
  }
  // set storage locale to user's browser language
  storage.setLocale(locale);
  switch (locale) {
    case 'ja':
    case 'ja-jp':
      return import('src/compiled-lang/ja.json');
    case 'th':
    case 'th-th':
      return import('src/compiled-lang/th.json');
    default:
      return import('src/compiled-lang/en.json');
  }
}

export function getUserCountry() {
  const userLanguage = navigator.language.toLowerCase(); // Get user's browser language
  let userCountry;
  // Map language codes to countries
  switch (userLanguage) {
    case 'ja':
    case 'ja-jp':
      userCountry = 'ja';
      break;
    case 'th':
    case 'th-th':
      userCountry = 'th';
      break;
    default:
      userCountry = 'th'; // Default to 'Unknown' for other languages
      break;
  }

  return userCountry;
}

function getCurrentLocale() {
  return storage.getLocale() ? storage.getLocale() : navigator.language.toLowerCase(); // Get user's browser language
}

export const useLocale = (
  initial = { current: storage.getLocale(), currentMsgs: { default: {} } }
) => {
  const [{ current, currentMsgs }, setCurrent] = React.useState(initial);

  React.useEffect(() => {
    const initFunc = async () => {
      console.log('useLocale initFunc');
      const locale = getCurrentLocale();
      dayjs.locale(locale);
      const messages = await loadLocaleData(locale);
      setCurrent({ current: locale, currentMsgs: messages });
    };
    console.log('useLocale useEffect');
    initFunc();
  }, []);

  const setLocale = React.useCallback(async (val) => {
    console.log('setLocalecallback');
    dayjs.locale(val);
    const messages = await loadLocaleData(val);
    setCurrent({ current: val, currentMsgs: messages });
  }, []);

  return { locale: current as string, messages: currentMsgs, setLocale };
};
