import {
  VStack,
  Grid,
  Text,
  Button,
  Divider,
  Checkbox,
  Link,
  useDisclosure,
} from '@chakra-ui/react';
import { customAlphabet } from 'nanoid';
import { useState, useRef } from 'react';
import { MdMailOutline } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';

import { LineLogin } from 'src/components/LineLogin';
import { useAuth } from 'src/lib/auth';
import { useNotificationStore } from 'src/stores/notifications';
import { consoleLog } from 'src/utils/consoleLog';

import { PhoneNumberModal } from '../components/PhoneNumberModal';

export const LoginFormTH = ({
  loginType,
  clinicId,
  clinicLINEChannel,
  onSuccess,
}: {
  loginType: string;
  clinicId: number;
  clinicLINEChannel: string;
  onSuccess: () => void;
}) => {
  const { addNotification } = useNotificationStore();
  const { isLoggingIn, login } = useAuth();
  const [isChecked, setIsChecked] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<any>('');
  const [phoneError, setPhoneError] = useState<any>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const nanoid = customAlphabet(
    'useandom26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict',
    15
  );
  const state = nanoid();
  const lineData = useRef(null);

  const provider = loginType == 'login' ? 'line' : 'line_register';

  let fullURL = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port) {
    fullURL += `:${window.location.port}`;
  }

  const lineRedirectUri =
    loginType == 'login'
      ? `${fullURL}/auth/login/th`
      : clinicId == null
      ? `${fullURL}/auth/register/country/th`
      : `${fullURL}/auth/register/clinic/linecallback`;

  /** set line data state after LINE redirect and back into our app (saving for phone input popup) */
  const setIdToken = (data: any) => {
    consoleLog('setIdToken ', data);
    lineData.current = data;
    // register should open phone input popup
    // loginType is set by the initial LINE login button parameters
    // then retrieved again through LINE redirect's query parameters
    handleLoginSkipPhone();
    //if (data?.loginType == 'register') {
    //  onOpen();
    //} else {
    //  handleLoginSkipPhone();
    //}
  };

  /** reset the states when there is a LINE error */
  const resetForm = (err: string) => {
    lineData.current = null;
    //setPhoneNumber('');
    //setPhoneError(false);
    //setIsChecked(false);
    onClose();
    addNotification({
      type: 'error',
      title: err,
    });
    navigate(window.location.pathname, { replace: true });
  };

  /** set phone state and error */
  const handlePhoneChange = (val: string) => {
    setPhoneError(false);
    setPhoneNumber(val);
  };

  /** login when user skips phone input */
  const handleLoginSkipPhone = async () => {
    const authCredentials = {
      code: (lineData.current as any)?.code,
      nonce: (lineData.current as any)?.nonce,
      provider: provider,
      country: 'th',
      channel: clinicLINEChannel,
      clinic: clinicId,
      phoneNumber: '',
    };
    try {
      await login(authCredentials);
      onSuccess();
    } catch (err: any) {
      consoleLog('err ', err);
      if (err?.data?.line) {
        resetForm(err?.data?.line.toString());
      }
    }
  };

  /** login with phone input. checks for valid phone input */
  const handleLogin = async () => {
    if (!isPossiblePhoneNumber(phoneNumber)) {
      setPhoneError(true);
      return;
    }
    const authCredentials = {
      code: (lineData.current as any)?.code,
      nonce: (lineData.current as any)?.nonce,
      provider: provider,
      country: 'th',
      channel: clinicLINEChannel,
      clinic: clinicId,
      phoneNumber: phoneNumber,
    };
    await login(authCredentials);
    onSuccess();
  };

  return (
    <Grid h="full" w="full">
      <PhoneNumberModal
        isOpen={isOpen}
        onClose={onClose}
        isLoggingIn={isLoggingIn}
        phoneError={phoneError}
        handleLoginSkipPhone={handleLoginSkipPhone}
        handleLogin={handleLogin}
        handlePhoneChange={handlePhoneChange}
      />
      <VStack mx={'auto'} h="full" spacing={8} w="280px" pt={[0, '20px']}>
        <Checkbox
          onChange={(e) => {
            setIsChecked(e.target.checked);
          }}
          alignItems={['flex-start']}
          colorScheme={'brand'}
          sx={{ '> span.chakra-checkbox__control': { mt: 1 } }}
        >
          <Text>
            <FormattedMessage
              id={'linelogin.email.consent.description.th'}
              description={
                'linelogin email consent reason, information. We will use your email address to find and connect your data.'
              }
              defaultMessage={
                '*ข้าพเจ้ายินยอมให้มีการเชื่อมข้อมูลส่วนตัวผ่านทาง LINE และยอมรับเงื่อนไขการใช้งานของแอพพลิเคชั่นนี้'
              }
            />
          </Text>
          <Link isExternal={true} href={'https://beforedent.com/th/privacy'} color="blue.400">
            <FormattedMessage
              id={'linelogin.email.consent.description.link.readmore.th'}
              description={'linelogin email consent description read more'}
              defaultMessage={'อ่านเพิ่มเติม'}
            />
          </Link>
        </Checkbox>
        <LineLogin
          loginType={loginType}
          clinicQueryId={clinicId}
          clientID={`${clinicLINEChannel}`}
          state={state}
          scope={'profile openid email'}
          locale={'th'}
          setIdToken={setIdToken}
          redirectURI={lineRedirectUri}
          isLoading={isLoggingIn}
          text={loginType == 'login' ? 'ล็อกอินด้วย LINE' : 'ลงทะเบียนกับ LINE'}
          isDisabled={!isChecked}
        />
        {process.env.REACT_APP_SHOW_EMAIL == 'true' && (
          <>
            <Divider borderColor="#c4c4c4" />
            <Button
              variant={'primary'}
              h="50px"
              leftIcon={<MdMailOutline fontSize={26} />}
              color="white"
              w="230px"
              borderRadius="12px"
              isDisabled={!isChecked}
              onClick={() => navigate('/auth/login/general')}
            >
              <Text ml={2}>
                <FormattedMessage
                  id={'login.loginwithemail.button.text.th'}
                  description={'login with email'}
                  defaultMessage={'เข้าสู่ระบบด้วยอีเมล'}
                />
              </Text>
            </Button>
          </>
        )}
      </VStack>
    </Grid>
  );
};
