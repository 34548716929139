import { Box } from '@chakra-ui/react';
import * as React from 'react';

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <Box bg={'#FFFFFF'} h="100%" w={['full', '100vw']} p={[0, 0]} m={['auto', 0]}>
      <Box h={'full'} py={[0, 0]}>
        {children}
      </Box>
    </Box>
  );
};
