//import { Navigate } from 'react-router';

import { Navigate } from 'react-router-dom';

import { lazyImport } from 'src/utils/lazyImport';
const { QRCodeRoutes } = lazyImport(() => import('src/features/qrcode'), 'QRCodeRoutes');
const { AuthRoutes } = lazyImport(() => import('src/features/auth'), 'AuthRoutes');
const { MiscSocialRoutes } = lazyImport(() => import('src/features/misc'), 'MiscSocialRoutes');
//const { RegisterRoutes } = lazyImport(() => import('src/features/register'), 'RegisterRoutes');

export const publicRoutes = [
  {
    path: '/register/*',
    element: <AuthRoutes />,
  },
  {
    path: '/social/*',
    element: <MiscSocialRoutes />,
  },
  {
    path: '/qr/*',
    element: <QRCodeRoutes />,
  },
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
  {
    path: '/ja',
    element: <Navigate to="/auth/login/ja" />,
  },
  {
    path: '/jp',
    element: <Navigate to="/auth/login/ja" />,
  },
  {
    path: '/th',
    element: <Navigate to="/auth/login/th" />,
  },
  {
    path: '*',
    element: <Navigate to="/auth/login" />,
  },
];
